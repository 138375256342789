import React from "react";
import "./App.css";

function App() {
  return (
    <div className="App">
      <div className="content">
        <h1>
          Manifesto for Non-Violence: Approach to Solving Political Unrest in
          Bangladesh
        </h1>
        <h2>
          <em>
            What began as a peaceful movement for job quotas has now descended
            into political disarray.
          </em>
        </h2>
        <h3>Country & Sacrifice</h3>
        <div className="dots">...</div>
        <p>
          <strong>All parties unite under the universal truth</strong>{" "}
          <em>
            that sustaining the country, above any party or person, comes first.
          </em>
        </p>
        <p>
          <strong>The current government commits</strong>{" "}
          <em>
            to skipping the upcoming general election but can participate as the
            opposition.
          </em>
        </p>
        <p>
          <strong>The current government commits</strong>{" "}
          <em>
            to fostering viable new or existing political parties until the next
            election.
          </em>
        </p>
        <p>
          <strong>Students and protest groups commit to normalcy</strong>{" "}
          <em>
            by suspending protests that harm the country and common people.
          </em>
        </p>
        <p>
          <strong>
            All interested groups prepare for the next general elections
          </strong>{" "}
          <em>by reforming and modernizing.</em>
        </p>
        <p>
          <strong>All current losses are regrettably unrecoverable,</strong>{" "}
          <em>
            but to sustain the country, all victims commit to voluntary
            forgiveness and healing.
          </em>
        </p>

        <p>
          <strong>No one can be a bystander;</strong>{" "}
          <em>act by urging leaders to commit to manifesto points.</em>
        </p>
        <p>
          <strong>Larger groups should advance the solution</strong>{" "}
          <em>
            by supporting the manifesto, reserving the right to scale back if
            others don't reciprocate.
          </em>
        </p>
        <div className="dots">...</div>
        <div className="disclaimer">
          <em>Informational purposes only</em>
        </div>
      </div>
    </div>
  );
}

export default App;
